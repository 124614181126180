import { getCountryCallingCode } from '@ali/i18n-utils';
import { ASCTools } from '@alife/workstation-utils';
import { result } from 'lodash';

export const CONST_COUNTRY = ASCTools.getCountry() || 'SG';
export const CONST_PHONE_CODE = `+${getCountryCallingCode(CONST_COUNTRY)}`;

export const formatPhone = (phone: string) => {
  if (!phone) return {};
  const nationCode = CONST_PHONE_CODE;
  if (phone.startsWith(CONST_PHONE_CODE)) {
    phone = phone.replace(CONST_PHONE_CODE, '');
  }
  return { nationCode, phone };
};
