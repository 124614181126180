import React, { createContext } from 'react';

export const FeedbackContext = createContext<{
  onScreenshotStart?: () => void;
  onScreenshotEnd?: () => void;
  disableScreenshot?: boolean;
  disableScreenRecord?: boolean;
  text?: { [key: string]: string };
  accountRequired?: 'either-or' | 'both';
}>({});
