import { useMcms } from '@ali/merlion-ui';
import * as $18n from '@alife/lazada-i18n';

export function useLocale(propsLocale) {
  // 判断是否有从lago传来的locale
  if (propsLocale) {
    $18n.registry(propsLocale);
    return {
      ...propsLocale,
    };
  }
  // 模块同步加载使用
  const [locale] = useMcms(
    {
      appName: 'aidc-seller-center',
      tag: 'asc-feedback',
    },
    {},
  );

  $18n.registry(locale);

  return {
    ...locale,
  };
}
