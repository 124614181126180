export class PreparingElement {
  static instance: PreparingElement;

  private wrapper: HTMLElement;
  private title: HTMLElement;
  private counter: HTMLElement;

  public static getInstance() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new this();
    return this.instance;
  }

  create(count: number) {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('asc-feedback-screen-record-countdown');

    this.title = document.createElement('div');
    this.title.classList.add('title');
    this.title.textContent = 'Recording starts in';

    this.counter = document.createElement('div');
    this.counter.classList.add('counter');
    this.counter.textContent = `${count}`;

    this.wrapper.appendChild(this.title);
    this.wrapper.appendChild(this.counter);

    document.body.appendChild(this.wrapper);
  }

  update(count: number) {
    this.counter.textContent = `${count}`;
  }

  remove() {
    this.wrapper.parentNode?.removeChild(this.wrapper);
  }
}

export class RecordingElement {
  static instance: RecordingElement;

  private wrapper: HTMLElement;
  private icon: HTMLElement;
  private text: HTMLElement;
  private separator: HTMLElement;
  private btn: HTMLElement;

  public static getInstance() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new this();
    return this.instance;
  }

  create(count: number, btnEventHandler) {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('asc-feedback-screen-record-recording');

    this.icon = document.createElement('span');
    this.icon.classList.add('icon');

    this.text = document.createElement('span');
    this.text.classList.add('text');
    this.text.textContent = `Screen is recording (00:${count < 10 ? '0' : ''}${count})`;

    this.separator = document.createElement('span');
    this.separator.textContent = '|';

    this.btn = document.createElement('div');
    this.btn.classList.add('btn');
    this.btn.id = 'autoFeedbackRecordingButton';
    this.btn.textContent = 'Stop Recording';

    if (btnEventHandler) {
      document.body.addEventListener('click', (event: any) => {
        // 检查事件是否来自特定的目标或者类名
        if (event && event?.target?.id === 'autoFeedbackRecordingButton') {
          // 如果是目标元素，执行相应的操作
          btnEventHandler();
        }
      });
    }

    this.wrapper.appendChild(this.icon);
    this.wrapper.appendChild(this.text);
    this.wrapper.appendChild(this.separator);
    this.wrapper.appendChild(this.btn);

    document.body.appendChild(this.wrapper);
  }

  update(count: number) {
    this.text.textContent = `Screen is recording (00:${count < 10 ? '0' : ''}${count})`;
  }

  remove() {
    this.wrapper.parentNode?.removeChild(this.wrapper);
  }
}
