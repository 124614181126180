const makeID = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const screenshotDataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const fileType = mimeString.split('/')[1];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const time = Date.now();
  const fileName = time + `.${fileType}`;
  const file = new File([ia], fileName, { type: mimeString, lastModified: time });

  return {
    lastModified: time,
    name: fileName,
    originFileObj: file,
    size: file.size,
    type: file.type,
    uid: makeID(8),
  };
};

export const screenrecordDataURItoBlob = (file, time) => {
  const type = (file.fileType || file.type).split('/');
  const fileType = type[type.length - 1];
  const fileName = time + `.${fileType}`;

  return {
    lastModified: time,
    name: fileName,
    originFileObj: file,
    size: file.size,
    type: file.type,
    uid: makeID(8),
  };
};
